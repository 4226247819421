<template>
  <v-dialog
    v-model="value"
    overlay-opacity="0.5"
    width="380px"
    persistent
    :overlay-color="!$vuetify.theme.dark ? 'white' : '#303030'"
  >
    <v-row no-gutters>
      <v-card width="412px" class="box-shadow pt-2 px-2">
        <v-card-title class="justify-center pt-1" style="color: #d1404a">
          <v-icon color="gray" class="pb-1 pr-1"> mdi-alert </v-icon>
          {{ $i18n.t("DELETE.title") }}
        </v-card-title>
        <v-card-text class="pb-2">
          <v-col cols="12">
            <span class="delete-dialog__card--content">{{
              $i18n.t("DELETE.message")
            }}</span>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-2" style="float: right">
          <v-btn @click="$emit('close')" small text>
            {{ $i18n.t("GENERAL.close") }}
          </v-btn>
          <v-btn
            depressed
            :loading="loading"
            small
            @click="$emit('remove')"
            dark
            color="primary"
          >
            {{ $i18n.t("DELETE.confirmation") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    loading: Boolean,
  },
  methods: {
    remove() {
      this.$emit("remove");
    },
  },
};
</script>

<style scoped>
.delete-dialog__card-content {
  font-size: 25px;
  line-height: 28px;
  text-align: left;
}

.box-shadow {
  border-radius: 8px;
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.15);
}
</style>
