<template>
  <v-dialog
    v-model="$props.value"
    max-width="1000"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card class="fill-height">
      <v-card-text class="pa-0 fill-height">
        <v-card-title class="headline font-weight-light pb-1">
          {{ $i18n.t("ADMIN.Import List Tool") }}
        </v-card-title>

        <v-stepper
          v-model="tab"
          class="elevation-0 transparent"
          :class="[{ 'fill-height': tab === 2 }]"
          :height="tab === 2 ? '550px' : null"
          non-linear
        >
          <v-stepper-header class="elevation-0 mx-6 mt-6">
            <v-stepper-step :complete="tab > 1" step="1">
              {{ $i18n.t("ADMIN.Upload file") }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="tab > 2" step="2">
              {{ $i18n.t("ADMIN.Match Fields") }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              {{ $i18n.t("ADMIN.Summary") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items class="fill-height">
            <div
              class="overflow-y-auto"
              :style="[
                {
                  'min-height': 'calc(100% - 96px) !important',
                },
                {
                  'max-height': 'calc(100% - 96px) !important',
                },
              ]"
              ref="stepperItems"
            >
              <v-stepper-content step="1">
                <!-- File drop box -->
                <div
                  class="pa-12 justify-center text-center"
                  style="
                    cursor: pointer;
                    border: 2px var(--v-secondary-lighten2) dashed;
                  "
                  :style="[{ fileDropBoxStyle }]"
                  @click="launchFilePicker"
                  @mouseover="dragover"
                  @mouseleave="dragleave"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <v-icon size="120" :color="iconColor">
                    mdi-cloud-upload
                  </v-icon>

                  <input
                    type="file"
                    ref="file"
                    @change="onChange($event.target.files)"
                    style="display: none"
                    accept="text/csv,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet"
                  />

                  <div class="d-block">
                    <span v-show="!buttons.continue.loading">
                      {{ $i18n.t("ADMIN.Choose file") }}
                      <br />
                      {{ $i18n.t("ADMIN.Maximum size") }}
                      {{ this.$props.fileSize / 1e6 }} MB
                    </span>

                    <!-- File data -->
                    <div v-if="file" class="text text-center mt-4">
                      <v-icon small>mdi-paperclip</v-icon>
                      {{ file.name }} -
                      {{ Math.round((file.size / 1024) * 100) / 100 }} KB
                      <v-icon
                        small
                        @click.stop="removeFile"
                        v-show="!buttons.continue.loading"
                        class="ml-4"
                        >mdi-close</v-icon
                      >
                    </div>
                  </div>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2" class="pt-0">
                <v-row no-gutters>
                  <v-col v-if="errors.length">
                    <v-alert dense outlined type="error">
                      {{ $i18n.t("Please check the following errors") }}:
                      <ul>
                        <li v-for="(error, index) in errors" :key="index">
                          {{ error.text }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-col>
                  <v-col cols="6" class="pa-0">
                    <v-checkbox
                      v-model="checkHeaders"
                      :label="$i18n.t('ADMIN.Include headers')"
                      class="mt-0"
                    ></v-checkbox
                  ></v-col>

                  <v-col cols="12">
                    <v-row v-for="(column, indx) in fileColumns" :key="indx">
                      <v-col cols="6" class="mb-5">
                        <p class="font-weight-bold" v-if="checkHeaders">
                          {{ uploadedPreview.at(0).at(column) }}
                        </p>
                        <v-list
                          dense
                          class="rounded pa-0 transparent"
                          style="border: 1px var(--v-secondary-lighten5) solid"
                        >
                          <template
                            v-for="(upload, index) in uploadedPreview.slice(
                              checkHeaders ? 1 : 0,
                              checkHeaders ? 4 : 3
                            )"
                          >
                            <v-list-item dense :key="index">
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="upload.at(column)"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider
                              v-if="
                                index <
                                uploadedPreview.slice(
                                  checkHeaders ? 1 : 0,
                                  checkHeaders ? 4 : 3
                                ).length -
                                  1
                              "
                              :key="'divider' + index"
                              style="border-color: var(--v-secondary-lighten5)"
                            ></v-divider>
                          </template>
                        </v-list>
                      </v-col>
                      <v-col cols="6" class="mb-5">
                        <p class="font-weight-bold">
                          {{ $i18n.t("ADMIN.Belongs to") }}:
                        </p>
                        <v-select
                          @change="onSelect($event, column)"
                          v-model="selects[column]"
                          :items="
                            [
                              {
                                text: $i18n.t('ADMIN.Skip this column'),
                                value: null,
                              },
                            ].concat($props.columns)
                          "
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                          clearable
                          hide-details="auto"
                        >
                        </v-select>
                        <div v-show="selects[column] === 'new-field'">
                          <label class="mt-5"
                            >{{ $i18n.t("ADMIN.Field name") }}:</label
                          >
                          <input
                            v-model="inputs[column]"
                            type="text"
                            class="form-control"
                            :placeholder="
                              $i18n.t('ADMIN.Name this field') + '..'
                            "
                            @change="onSelect($event, column)"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-row no-gutters>
                  <v-col cols="12">
                    <slot name="extraStep3"> </slot>
                  </v-col>
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-left"
                              v-for="col in saveColumns"
                              :key="col.columnSystem"
                            >
                              {{
                                $props.columns.filter(
                                  (c) => c.value === col.columnSystem
                                )[0].text
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(upload, index) in uploadedPreview.slice(
                              checkHeaders ? 1 : 0,
                              checkHeaders ? 4 : 3
                            )"
                            :key="index"
                          >
                            <td
                              class="text-left"
                              v-for="col in saveColumns"
                              :key="col.columnFile"
                            >
                              {{ upload.at(col.columnFile) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </div>
          </v-stepper-items>
        </v-stepper>

        <v-card-actions class="pa-4 pt-4">
          <v-row no-gutters class="d-flex justify-center align-center">
            <v-col cols="4" class="mr-5">
              <v-btn
                text
                outlined
                rounded
                block
                @click.native="tab === 1 ? closeDialog() : (tab += -1)"
                :disabled="buttons.close.disabled"
                >{{ $i18n.t("ADMIN.Back") }}</v-btn
              >
            </v-col>
            <v-col cols="4" class="ml-5">
              <v-btn
                color="primary"
                rounded
                block
                :loading="buttons.continue.loading"
                class="px-5"
                depressed
                :disabled="buttons.continue.disabled || !this.file"
                @click.native="
                  tab === 1
                    ? uploadFile()
                    : tab === 2
                    ? summary()
                    : $props.type === 'json'
                    ? makeJSON()
                    : makeCSV()
                "
                >{{
                  tab === 3
                    ? $i18n.t("ADMIN.Finish")
                    : $i18n.t("ADMIN.Continue")
                }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./importListWorker.js";

export default {
  name: "ImportListTool",

  props: {
    value: { type: Boolean },
    type: { type: String, default: null },
    columns: { type: Array, default: null },
    fileSize: { type: Number, default: 82000000 },
    dataValidation: { type: Object, default: null },
    returnFilename: { type: Boolean },
  },

  watch: {
    value: {
      handler: function (val) {
        if (val) {
          this.importWorker = new Worker();
          this.importWorker.addEventListener(
            "message",
            (e) => {
              this.processUploaded(e.data);
              this.tab = 2;
              this.buttons.continue.loading = false;
            },
            false
          );
        } else {
          this.importWorker.terminate();
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      dialogs: {},
      tab: 1,
      file: null,
      filename: "",
      checkHeaders: false,
      uploaded: [],
      uploadedPreview: [],
      fileColumns: [],
      inputs: [],
      selects: [],
      errors: [],
      disableButtons: false,
      importWorker: null,
      buttons: {
        continue: {
          loading: false,
          disabled: false,
        },
        close: {
          disabled: false,
        },
      },
      saveColumns: [],
      fileTypesAccepted: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": true,
        "text/csv": true,
        "text/plain": true,
        "application/vnd.oasis.opendocument.spreadsheet": true,
      },
      fileDropBoxStyle: "",
      iconColor: "grey darken-2",
    };
  },

  async created() {},

  methods: {
    dragover(event) {
      event.preventDefault();
      this.fileDropBoxStyle = this.$vuetify.theme.dark
        ? "background-color: #414141;"
        : "background-color: #f0f0f0;";
      this.iconColor = "grey darken-1";
    },

    dragleave(event) {
      event.preventDefault();
      this.fileDropBoxStyle = "";
      this.iconColor = "grey darken-2";
    },

    drop(event) {
      event.preventDefault();
      this.onChange(event.dataTransfer.files); // Trigger the onChange event manually
    },

    removeFile() {
      this.$refs.file.value = null;
      this.cleanFile();
      this.cleanAll();
    },

    launchFilePicker() {
      this.removeFile();
      this.$refs.file.click();
    },

    onChange(file) {
      try {
        if (file && file[0] && this.fileTypesAccepted[file[0].type]) {
          if (file[0].size <= this.$props.fileSize) {
            this.cleanFile();
            this.cleanAll();
            this.file = file[0];
            this.filename = file[0].name;
          } else {
            this.$notify({
              group: "app",
              duration: 2000,
              text: `${"Max file size"} ${this.$props.fileSize / 1e6} MB`,
            });
          }
        } else {
          this.$notify({
            group: "app",
            duration: 2000,
            text: "Invalid File",
          });
        }
      } catch (e) {
        console.log(e);
        this.$notify({
          group: "app",
          duration: 2000,
          text: "Something went wrong",
        });
      }
    },

    async uploadFile() {
      if (this.file) {
        this.buttons.continue.loading = true;
        this.fileDropBoxStyle = "";
        this.cleanAll();
        this.importWorker.postMessage(this.file);
      }
    },

    processUploaded(uploaded) {
      try {
        if (uploaded.length > 0) {
          this.uploaded = uploaded;

          // Preview rows
          for (const [index, row] of uploaded.entries()) {
            this.uploadedPreview.push(row);
            if (index === 4) break;
          }

          // Set columns
          this.fileColumns = Array.from(
            { length: uploaded.at(0).length },
            (n, i) => i
          );

          // Default selected option
          this.selects = Array.from(
            { length: uploaded.at(0).length },
            () => null
          );

          this.uploaded = uploaded;

          // Restore savedColumns from LocalStorage
          let savedColumnsStorage = "";

          savedColumnsStorage = this.getFromLocalStorage("fileImport");

          if (savedColumnsStorage) {
            const saveColumns = JSON.parse(savedColumnsStorage);
            for (let i = 0; i < uploaded.at(0).length; i++) {
              if (
                saveColumns[i] &&
                this.$props.columns.some(
                  (col) => col.value === saveColumns[i].columnSystem
                )
              ) {
                if (
                  typeof this.selects[saveColumns[i].columnFile] !== "undefined"
                ) {
                  this.selects[saveColumns[i].columnFile] =
                    saveColumns[i].columnSystem;
                  this.saveColumns.push(saveColumns[i]);
                }
              }
            }
            if (
              typeof saveColumns[saveColumns.length - 1].checkHeaders ===
              "boolean"
            ) {
              this.checkHeaders =
                saveColumns[saveColumns.length - 1].checkHeaders;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    summary() {
      this.errors = [];
      if (this.saveColumns.length) {
        // Check required fields
        this.$props.columns.forEach((col) => {
          if (col.required) {
            if (
              !this.saveColumns.some(
                (saved) => saved.columnSystem === col.value
              )
            )
              this.errors.push({
                text: `${col.text} ${this.$i18n.t("is required")}`,
              });
          }
        });
      } else {
        this.errors.push({
          text: this.$i18n.t("You must select at least one column"),
        });
      }

      if (!this.errors.length) this.tab = 3;
      else this.$refs.stepperItems.scrollTop = 0;
    },

    onSelect(val, column) {
      // If already exist selected column
      const existColSys = this.saveColumns.findIndex(
        (col) => col.columnSystem === val
      );

      if (existColSys !== -1) {
        this.selects[this.saveColumns.at(existColSys).columnFile] = null;
        this.saveColumns.splice(existColSys, 1);
      }

      const existColFile = this.saveColumns.findIndex(
        (col) => col.columnFile === column
      );

      if (existColFile !== -1) this.saveColumns.splice(existColFile, 1);

      if (val) {
        this.saveColumns.push({
          columnSystem: val === "new-field" ? this.inputs.at(column) : val,
          columnFile: column,
        });
      } else {
        this.selects[column] = null;
      }
    },

    makeCSV() {
      try {
        this.buttons.continue.loading = true;

        let string = "";
        let sentence = "";

        this.saveInLocalStorage(
          "importList",
          JSON.stringify(
            this.saveColumns.concat([{ checkHeaders: this.checkHeaders }])
          )
        );

        for (const [index, row] of this.uploaded.entries()) {
          // If there are headers skip this row
          if (index === 0 && this.checkHeaders) continue;

          // Fields
          string +=
            this.saveColumns
              .map((paran) => {
                if (
                  typeof this.$props.columns.at(paran.columnFile).replace !==
                    "undefined" &&
                  row[paran.columnFile]
                ) {
                  return this.$props.columns
                    .at(paran.columnFile)
                    .replace(row[paran.columnFile]);
                }
                if (
                  typeof this.$props.columns.at(paran.columnFile).validation !==
                  "undefined"
                ) {
                  const validation = this.$props.columns
                    .at(paran.columnFile)
                    .validation(
                      row[paran.columnFile],
                      this.$i18n,
                      this.$props.dataValidation
                    );
                  if (validation === true) return row[paran.columnFile];
                  else {
                    if (typeof validation === "string") {
                      this.errors.push({
                        text: `${validation} ${this.$i18n.t("in line")} ${
                          index + 1
                        }`,
                      });
                      return null;
                    } else {
                      this.addError(paran.columnSystem, index);
                      return null;
                    }
                  }
                }
                return null;
              })
              .join(";") + "\n";

          if (this.errors.length > 0) break;
        }

        // Columns
        sentence +=
          "(" +
          this.saveColumns.map((paran) => paran.columnSystem).join(",") +
          ")";

        if (string !== "" && this.errors.length === 0) {
          const blob = new Blob([string], { type: "text/plain" });

          const file = new File([blob], this.filename);

          this.$emit("result", { file, sentence });

          this.closeDialog();

          this.$notify({
            group: "feedback",
            duration: 2000,
            type: "success",
            text: "Success",
          });
        } else {
          this.tab = 2;
          this.buttons.continue.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.errors.push({
          text: `${this.$i18n.t("Unexpected error. Contact an administrator")}`,
        });
        this.tab = 2;
        this.buttons.continue.loading = false;
      }
    },

    makeJSON() {
      try {
        const json = [];
        this.buttons.continue.loading = true;

        this.saveInLocalStorage(
          "fileImport",
          JSON.stringify(
            this.saveColumns.concat([{ checkHeaders: this.checkHeaders }])
          )
        );

        for (const [index, row] of this.uploaded.entries()) {
          // If there are headers skip this row
          if (index === 0 && this.checkHeaders) continue;

          const obj = {};

          // Fields
          this.saveColumns.forEach((paran) => {
            const colSysIndex = this.$props.columns.findIndex(
              (c) => c.value === paran.columnSystem
            );
            if (
              typeof this.$props.columns.at(colSysIndex).replace !==
                "undefined" &&
              row[paran.columnFile]
            ) {
              obj[paran.columnSystem] = this.$props.columns
                .at(colSysIndex)
                .replace(row[paran.columnFile]);
            }
            if (
              typeof this.$props.columns.at(colSysIndex).validation !==
              "undefined"
            ) {
              const validation = this.$props.columns
                .at(colSysIndex)
                .validation(
                  obj[paran.columnSystem]
                    ? obj[paran.columnSystem]
                    : row[paran.columnFile],
                  this.$i18n,
                  this.$props.dataValidation
                );
              if (validation === true)
                obj[paran.columnSystem] = obj[paran.columnSystem]
                  ? obj[paran.columnSystem]
                  : row[paran.columnFile];
              else {
                if (typeof validation === "string")
                  this.errors.push({
                    text: `${validation} ${this.$i18n.t("in line", {
                      line: index + 1,
                    })}`,
                  });
                else this.addError(paran.columnSystem, index);
              }
            } else {
              obj[paran.columnSystem] = obj[paran.columnSystem]
                ? obj[paran.columnSystem]
                : row[paran.columnFile];
            }
          });

          json.push(obj);

          if (this.errors.length > 0) break;
        }

        if (json.length && this.errors.length === 0) {
          if (this.$props.returnFilename)
            json.push({ filename: this.filename });
          this.$emit("result", json);

          this.closeDialog();

          this.$notify({
            group: "app",
            duration: 2000,
            text: "Success",
          });
        } else {
          this.tab = 2;
          this.buttons.continue.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.errors.push({
          text: `${this.$i18n.t("Unexpected error. Contact an administrator")}`,
        });
        this.tab = 2;
        this.buttons.continue.loading = false;
      }
    },

    addError(column, row) {
      const searchColName = this.$props.columns.filter(
        (col) => col.value === column
      );
      let colName = "";
      if (searchColName.length > 0) colName = searchColName.at(0).text;
      else colName = column;
      this.errors.push({
        text: `${this.$i18n.t(
          "importLists.errorColumn"
        )} ${colName} ${this.$i18n.t("importLists.errorLine")} ${row + 1}`,
      });
    },

    closeDialog() {
      this.cleanFile();
      this.cleanAll();
      this.$emit("input", false);
      this.tab = 1;
    },

    cleanFile() {
      this.filename = "";
      this.file = null;
    },

    cleanAll() {
      this.uploaded = [];
      this.uploadedPreview = [];
      this.errors = [];
      this.fileColumns = [];
      this.saveColumns = [];
      this.inputs = [];
      this.selects = [];
      this.checkHeaders = false;
      this.buttons = {
        continue: {
          loading: false,
          disabled: false,
        },
        close: {
          disabled: false,
        },
      };
    },

    saveInLocalStorage(key, value) {
      localStorage.setItem(key, value);
    },

    getFromLocalStorage(key) {
      if (localStorage.getItem(key) != null) {
        return localStorage.getItem(key);
      }
    },
  },
};
</script>
